const payload = {
  date:              null,
  construction_type: null,
  area:              null,
  painting_type:     null,
  adjustments:       null,
  supplementals:     [],
  options:           [],
};

const selectors = {
  date:              "#estimate-date",
  area:              "#estimate-area",
  construction_type: "input[type='radio'].construction-type",
  painting_type:     "input[type='radio'].painting-type",
  supplementals:     "input[type='checkbox'].incidental",
  options:           "input[type='checkbox'].options",
  adjustments:       "#estimate-adjustments",
  audio:             "#audio",
};

function initCommon() {
  const audioSelectors = "#new-project-form, #new-estimate-form, #project-complete-form";
  $(document).off("click", "#print");
  $(document).off("submit", audioSelectors);
  $(document).on("click", "#print", onPrint);
  $(document).on("submit", audioSelectors, playAudio);
}

async function onPrint(e) {
  if (!e.target.classList.contains("ignore")) {
    if (!confirm("この書類が発行済へと変更されます、続行してもよろしいですか？")) return;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const data = e.target.dataset;
    await fetch(data.url, {
      method: "PUT",
      body: data.body,
      headers: new Headers({
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      }),
    });
  }
  window.print();
}

function playAudio(e) {
  e.preventDefault();
  const audio = document.getElementById("audio");
  if (audio) {
    audio.currentTime = 0;
    audio.play();
  }
  setTimeout(() => e.target.submit(), 4000);
}

// PROJECT && ESTIMATES
function initEstimateForm() {
  registerEvents();
  Object.keys(payload).forEach(key => {
    if (["supplementals", "options"].includes(key)) {
      const items = document.querySelectorAll(`${selectors[key]}:checked`);
      if (!items.length) return;
      handleChange(key, Array.from(items).map(item => ({ label: item.dataset.label, value: item.id })), { array: true, skipFetch: true });
    } else {
      const item = $(selectors[key]).val();
      if (!item) return;
      handleChange(key, item, { currency: key == "adjustments", skipFetch: true });
    }
  });
  if (validRequest()) fetchAmount();
}

function collectCheckboxValues(key) {
  const items = document.querySelectorAll(`${selectors[key]}:checked`);
  return Array.from(items).map(item => ({ label: item.dataset.label, value: item.id }));
}

function handleChange(key, value, opts = {}) {
  payload[key] = opts.array ? value.map(v => v.value) : value;
  if (!value) {
    const total = $("#result-total");
    if (!payload.area && total.text()) total.text("");
    return;
  }
  if (opts.array) {
    const $container = $(`#result-${key}`);
    $container.empty();
    value.forEach((item, index) => {
      if (index > 0) $container.append(`<li class="divider">/</li>`);
      $container.append(`<li>${item.label}</li>`);
    });
  } else {
    let result = value;
    if (opts.currency) result = parseInt(result);
    result = result.toLocaleString("ja-JP");
    if (opts.currency) result += "円";
    $(`#result-${key}`).text(result);
  }
  if (opts.skipFetch) return;
  if (validRequest()) fetchAmount();
}

function registerEvents() {
  $(document).on("change", selectors.date, e => handleChange("date", e.target.value));
  $(document).on("change", selectors.construction_type, e => handleChange("construction_type", e.target.value));
  $(document).on("change", selectors.area, e => handleChange("area", e.target.value));
  $(document).on("change", selectors.painting_type, e => handleChange("painting_type", e.target.value));
  $(document).on("change", selectors.supplementals, () => handleChange("supplementals", collectCheckboxValues("supplementals"), { array: true }));
  $(document).on("change", selectors.options, () => handleChange("options", collectCheckboxValues("options"), { array: true }));
  $(document).on("change", selectors.adjustments, e => handleChange("adjustments", e.target.value, { currency: true }));
}

function validRequest() {
  return payload.construction_type && payload.painting_type && payload.area;
}

async function fetchAmount() {
  const res = await fetch("/api/calculate", {
    headers: { "Accept": "application/json", "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(payload),
  });
  const result = (await res.json()).result;
  if (!result) return;
  Object.keys(result).forEach(key => {
    if (key === "options") return;
    $(`#${key}`).val(result[key]);
  });
  $("#result-amount").text(`${result.amount.toLocaleString("ja-JP")} 円`);
  $("#result-total").text(`${result.total.toLocaleString("ja-JP")} 円`);
}

// INVOICES
function initInvoices() {
  $(document).on("change", "#new-invoice-estimates", e => {
    window.location = `${window.location.origin}${window.location.pathname}?estimate_id=${e.target.value}`;
  });
  $(document).on("change", "#invoice-adjustments", invoiceCalculations);
}

function invoiceCalculations() {
  const originalAmount = parseInt($("#invoice-amount").data("original"));
  const originalAdjustments = parseInt($("#invoice-adjustments").data("original"));
  let newAdjustments = parseInt($("#invoice-adjustments").val());
  if (!newAdjustments) newAdjustments = 0;
  const newAmount = (originalAmount + originalAdjustments) - newAdjustments;
  const tax = Math.round(newAmount * 0.1);
  $("#invoice-amount").val(newAmount);
  $("#invoice-tax").val(tax);
  $("#invoice-total").val(newAmount + tax);
}

$(document).on('turbolinks:load', function () {
  const body = $(document.body);
  if ((body.hasClass("controller-projects") || body.hasClass("controller-estimates")) && body.hasClass("action-new")) {
    initEstimateForm();
  }
  if (body.hasClass("controller-invoices") && (body.hasClass("action-new") || body.hasClass("action-edit"))) {
    initInvoices();
  }
  initCommon();
});
