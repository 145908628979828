// Entry point for the build script in your package.json

import '@fortawesome/fontawesome-free/js/all';

window.$ = require("jquery");

require("@rails/ujs").start();
require("turbolinks").start();

require("@rails/activestorage").start();
// require("channels");
require("bootstrap");

require("./script");
